import { Injectable } from '@angular/core';
import { WebApiFactoryService } from '../web-api-factory.service';
import { UserSettingsApi } from 'business/dist/dataverse/usersettings-api';
import { BaghiraUserSettings } from 'business';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private static readonly DefaultThousandsSeparator: string = ',';
  private static readonly DefaultDecimalPoint: string = '.';

  private userSettings: BaghiraUserSettings | null = null;

  constructor(private webApiFactory: WebApiFactoryService) {}

  getThousandSeparator(): string {
    return (
      this.userSettings?.numberseparator ?? UserSettingsService.DefaultThousandsSeparator
    );
  }

  getDecimalPoint(): string {
    return this.userSettings?.decimalsymbol ?? UserSettingsService.DefaultDecimalPoint;
  }

  getUserSettings(): BaghiraUserSettings | null {
    return this.userSettings;
  }

  async init(): Promise<void> {
    await this.loadUserSettings();
  }

  private async loadUserSettings(): Promise<void> {
    const config = await this.webApiFactory.createWebApiConfig();
    const settings = await UserSettingsApi.getUserSettings(config, this.getUserId());
    if (!settings || settings.length < 1) {
      return;
    }
    console.log('UserSettingsService.getUserSettings loaded', settings[0]);
    this.userSettings = settings[0];
  }

  private getUserId(): string {
    let userId = 'aa553bca-8a8e-ee11-8179-6045bd8953b8'; // Max
    if (typeof Xrm !== 'undefined') {
      userId = Xrm.Utility.getGlobalContext().userSettings.userId;
    }
    return userId;
  }
}
